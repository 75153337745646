import { api, param2query } from "./api.service";

export const getAll = (params, cancelToken) => {
  return api.get(
    "motivospendenciaauditoria/listar?" + param2query(params),
    cancelToken
  );
};

export const getAllMotivosPendenciaAuditoria = (id) => {
  return api.get(
    "motivospendenciaauditoria/listar-motivospendenciaauditoria/" + id
  );
};

export const getLabels = (params) => {
  return api.get("motivospendenciaauditoria/labels?" + param2query(params));
};

export const getMotivosPendenciaAuditoria = (id) => {
  return api.get("motivospendenciaauditoria/" + id);
};

export const criarMotivosPendenciaAuditoria = (model) => {
  return api.post("motivospendenciaauditoria", model);
};

export const atualizarMotivosPendenciaAuditoria = (model) => {
  return api.put("motivospendenciaauditoria", model);
};

export const pesquisarMotivosPendenciaAuditoria = (query) => {
  return api.get("motivospendenciaauditoria/buscar-descricao?filtro=" + query);
};

export default { getAll };
